import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '../../../components/container';
import { SignHeader } from './sign-header';
import { ROUTES } from '../../../utils/router';
import TOUCH from '../../../assets/touch.svg';
import { SvgSelector } from '../../../assets/icons/svg-selector';
export const SignContract = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (_jsxs("div", { className: "relative", children: [_jsx("div", { children: _jsxs("div", { className: "fixed w-full top-0 z-10 bg-[#000015]", children: [_jsx(SignHeader, {}), _jsx("div", { className: "header-border" })] }) }), _jsxs(Container, { className: "mt-5 pt-[70px]", children: [_jsxs("div", { className: "text-center", children: [_jsx("div", { className: "mb-2.5 text-[16px] font-bold leading-[156%]", children: t('task-detail.sign-contract.content-title') }), _jsxs("p", { className: "opacity-40 leading-[166%]", children: [t('task-detail.sign-contract.content-description1'), " ", _jsx("br", {}), " ", t('task-detail.sign-contract.content-description2')] })] }), _jsxs("div", { className: "flex items-center justify-center flex-col cursor-pointer pt-[120px]", onClick: () => navigate(ROUTES.DESIGNATED_PERFORMER), children: [_jsxs("div", { className: "text-blue_color text-[16px] font-extrabold relative top-[45px] text-center", children: [t('task-detail.sign-contract.freeze'), " 7 777 TON ", _jsx("br", {}), " ", t('task-detail.sign-contract.appoint-an-executor')] }), _jsx(SvgSelector, { id: "rectangle" })] }), _jsx("img", { src: TOUCH, alt: "touch", className: "mx-auto" }), _jsxs("p", { className: "opacity-40 leading-[166%] mt-[9px] pb-3", children: [t('task-detail.sign-contract.description') + ' ', _jsx("a", { href: "#", className: "underline", children: "TON Jobs DAO" }), "."] })] })] }));
};
