import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '../../../components/container';
import { CongratulationsHeader } from '../../../components/congratulations-header';
import { Button } from '../../../components/ui/button';
import { ROUTES } from '../../../utils/router';
export const TaskCreated = () => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: "flex flex-col justify-between min-h-screen relative", children: [_jsx("div", { children: _jsxs("div", { className: "fixed z-10 bg-[#000015] top-0 w-full", children: [_jsx(CongratulationsHeader, { title: t('task-created-page.title') }), _jsx("div", { className: "header-border" })] }) }), _jsx(Container, { className: "flex flex-col justify-center pt-[60px] items-center h-full relative", children: _jsxs("div", { className: "text-center flex flex-col items-center justify-center", children: [_jsxs("h3", { className: "font-ebGaramond text-6xl leading-[77%] italic mb-[18px] max-w-[200px]", children: [t('task-created-page.content1'), " ", _jsx("br", {}), _jsx("div", { className: "underline text-blue_color", children: t('task-created-page.content2') })] }), _jsxs("div", { className: "opacity-40 leading-5 mx-auto max-w-[260px]", children: [t('common.my-tasks-content'), " ", _jsx("span", { children: " " }), _jsx(Link, { to: ROUTES.MY_TASKS, className: "underline hover-blue", children: t('common.my-tasks') })] })] }) }), _jsx("div", { className: "pb-[30px] relative px-[20px]", children: _jsx("div", { className: "mt-auto flex flex-col items-center justify-center", children: _jsx(Link, { to: ROUTES.MY_TASKS, className: "w-full mb-3.5 xs:w-[375px] mx-auto", children: _jsx(Button, { size: "LG", className: "font-extrabold", children: t('common.my-tasks-link') }) }) }) })] }));
};
