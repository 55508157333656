import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-multi-assign */
/* eslint-disable import/no-cycle */
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NewTaskContext } from '../../pages/create-task';
import { Button } from '../ui/button';
export const FourthStep = () => {
    const { setCurrentStep, setTaskData, taskData, currentStep } = useContext(NewTaskContext);
    const [amount, setAmount] = useState(0);
    const { t } = useTranslation();
    const handleChange = (e) => {
        const { value } = e.target;
        const numberValue = Number(value.replace(/\D/g, ''));
        setAmount(numberValue);
        const cursorPos = value.length;
        if (numberValue > 0) {
            e.target.value = value + '💎';
        }
        else {
            e.target.value = '';
        }
        e.target.selectionStart = e.target.selectionEnd = cursorPos;
    };
    const handleClick = () => {
        if (!amount)
            return;
        setTaskData({ ...taskData, budget: amount });
        setCurrentStep(currentStep + 1);
    };
    return _jsxs("div", { className: "flex flex-col min-h-[80vh] justify-between", children: [_jsxs("div", { children: [_jsx("h3", { className: "text-base font-bold leading-[156%] mb-2.5", children: t('create-task-page.fourth-step-page.title') }), _jsxs("div", { className: "flex flex-col gap-2.5 mb-2", children: [_jsx("input", { type: "text", className: "bg-transparent focus:outline-none", value: amount + '💎', onChange: handleChange }), _jsx("div", { className: "decor-line" })] }), _jsx("p", { className: "opacity-40", children: t('create-task-page.fourth-step-page.content') })] }), _jsx("div", { children: _jsx(Button, { size: "LG", disabled: amount === 0, onClick: handleClick, className: "w-full xs:w-[375px] mx-auto", children: t('common.next') }) })] });
};
