import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-cycle */
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v1 } from 'uuid';
import { NewTaskContext } from '../../pages/create-task';
export const FirstStep = () => {
    const [variant, setVariant] = useState('');
    const { t } = useTranslation();
    const variantsData = [
        t('categories.cat1'),
        t('categories.cat2'),
        t('categories.cat3'),
        t('categories.cat4'),
        t('categories.cat5'),
        t('categories.cat6'),
        t('categories.cat7'),
        t('categories.cat8'),
        t('categories.cat9')
    ];
    const { setCurrentStep, setTaskData, taskData, currentStep } = useContext(NewTaskContext);
    const handleClick = (selectedVariant) => {
        setVariant(selectedVariant);
        setTaskData({
            ...taskData,
            category: selectedVariant
        });
        setCurrentStep(currentStep + 1);
    };
    return (_jsxs("ul", { className: "flex flex-col gap-5", children: [_jsx("h2", { className: "text-[16px]", children: t('create-task-page.first-step-page.title') }), variantsData.map(el => (_jsx("li", { onClick: () => handleClick(el), className: "px-2.5 py-[15px] rounded-md text-[12px] text-blue_color border border-[#3A4362] font-medium leading-[166%] cursor-pointer transition duration-300 hover:border-[0.5px] hover:border-white", children: el }, v1())))] }));
};
