import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { v1 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { CategoryHeader } from './category-filter/category-header';
import { CategoriesList } from '../../../../mockup/categories';
import { Container } from '../../../../components/container';
export const Category = ({ RawAddress, setShowCategories, setActiveCategory }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: "relative", children: [_jsx("div", { className: "relative", children: _jsxs("div", { className: "fixed w-full top-0 z-10 bg-[#000015]", children: [_jsx(CategoryHeader, { RawAddress: RawAddress, setShowCategories: setShowCategories }), _jsx("div", { className: "header-border" })] }) }), _jsx(Container, { className: "mt-5 pt-[65px]", children: _jsx("ul", { className: "flex flex-col gap-5", children: CategoriesList.map((category) => (_jsx("li", { onClick: () => {
                            setActiveCategory(category);
                            setShowCategories(false);
                        }, className: "px-2.5 py-[15px] rounded-md text-[12px] text-blue_color border border-[#3A4362] font-medium leading-[166%] cursor-pointer transition duration-300 hover:border-[0.5px] hover:border-white", children: t(category.title) }, v1()))) }) })] }));
};
