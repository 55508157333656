import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
var ButtonSizeEnum;
(function (ButtonSizeEnum) {
    ButtonSizeEnum["BASE"] = "BASE";
    ButtonSizeEnum["LG"] = "LG";
})(ButtonSizeEnum || (ButtonSizeEnum = {}));
var ButtonVariantEnum;
(function (ButtonVariantEnum) {
    ButtonVariantEnum["BASE"] = "BASE";
    ButtonVariantEnum["OUTLINE"] = "OUTLINE";
})(ButtonVariantEnum || (ButtonVariantEnum = {}));
export const Button = ({ size = ButtonSizeEnum.BASE, variant = ButtonVariantEnum.BASE, children, startIcon, endIcon, className, ...buttonProps }) => {
    const btnClasses = clsx('cursor-pointer text-bg_color transition-all duration-300 ease-in-out', size === ButtonSizeEnum.BASE && 'text-[15px] font-semibold bg-blue_color py-[5px] px-[13px] rounded-3xl flex items-center gap-2 hover:bg-[#1093eb]', size === ButtonSizeEnum.LG && 'text-[16px] font-bold bg-blue_color py-[15px] px-[8px] rounded-3xl text-center flex items-center justify-center gap-2 w-full disabled:opacity-30 hover:bg-[#1093eb]', className);
    return (_jsxs("button", { className: btnClasses, ...buttonProps, children: [startIcon && _jsx("img", { src: startIcon, alt: "icon" }), children, endIcon && _jsx("img", { src: endIcon, alt: "icon" })] }));
};
