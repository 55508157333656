import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SvgSelector } from '../../../assets/icons/svg-selector';
export const CreateHeader = ({ currentStep, setCurrentStep, category }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleBack = () => {
        setCurrentStep(currentStep - 1);
    };
    const title = category.length > 27 ? category.slice(0, 27) + '...' : category;
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex items-center justify-between gap-[15px] p-[20px]", children: [currentStep > 1 ? _jsx("button", { onClick: handleBack, children: _jsx(SvgSelector, { id: "back" }) }) : _jsx("span", {}), _jsxs("div", { className: "flex flex-col items-center justify-center", children: [_jsx("div", { children: currentStep <= 1 ? _jsx("h2", { className: "font-semibold", children: t('create-task-page.header-title') }) : title }), _jsx("span", { className: "opacity-40 text-[10px]", children: currentStep <= 1 ? (t('create-task-page.first-step')) : (`${t('create-task-page.step')} ${currentStep} ${t('create-task-page.of')} 6`) })] }), _jsx("button", { onClick: () => navigate(-1), children: _jsx(SvgSelector, { id: "close" }) })] }) }));
};
