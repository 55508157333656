import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
export const ExpandableText = ({ text, className }) => {
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation();
    const toggleExpanded = () => {
        setExpanded(!expanded);
    };
    const renderParagraphs = (content) => {
        const paragraphs = content.split('\n\n');
        return paragraphs.map((paragraph, index) => (_jsx("p", { className: `mb-4 ${expanded || index < 2 ? 'block' : 'hidden'}`, children: paragraph.split('\n').map((line, i) => (_jsxs(Fragment, { children: [line, _jsx("br", {})] }, i))) }, index)));
    };
    const truncatedText = text.length > 260 ? text.substring(0, 260) + '...' : text;
    return (_jsxs("div", { className: `relative ${className}`, children: [renderParagraphs(expanded ? text : truncatedText), text.length > 260 && (_jsx("button", { onClick: toggleExpanded, className: `mt-2 text-[#00D1FF] absolute right-0 bottom-0 bg-black-gradient text-right transition duration-300 hover:text-white ${!expanded ? 'w-full' : 'bottom-[-20px]'}`, children: expanded ? t('common.hide') : t('common.show') }))] }));
};
