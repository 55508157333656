import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable import/namespace */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTonAddress } from '@tonconnect/ui-react';
import { Button } from '../../../components/ui/button';
import { Container } from '../../../components/container';
import { FilterHeader } from './filter-header';
import { SomeAPI } from '../../../logic/some-api';
import { Category } from './category';
import { SvgSelector } from '../../../assets/icons/svg-selector';
export const Filter = ({ setActiveScreen, searchResults, setSearchResults }) => {
    const { t } = useTranslation();
    // Стоимость заданий от
    const [amount, setAmount] = useState(undefined);
    const [amountPlaceholder, setAmountPlaceholder] = useState(t('home.filter-amount'));
    const [showCategories, setShowCategories] = useState(false);
    // По дате публикации
    const [sortByDate, setSortByDate] = useState(false);
    // По срочности
    const [sortByUrgency, setSortByUrgency] = useState(false);
    // Категории
    const [activeCategory, setActiveCategory] = useState({
        title: 'Блокчейн TON',
        category: 'Блокчейн TON' // TODO: Когда будут таски, изменить категорию на "turnkey-project"
    });
    const handleAmountChange = (e) => {
        const inputValue = e.target.value;
        if (!isNaN(Number(inputValue))) {
            setAmount(Number(inputValue));
        }
    };
    const RawAddress = useTonAddress();
    const applyFilters = async () => {
        const api = new SomeAPI();
        const queryParams = {
            category: activeCategory.category,
            orderBy: sortByDate
                ? 'creation_unix_time'
                : sortByUrgency
                    ? 'ending_unix_time'
                    : undefined,
            minAmount: amount
        };
        try {
            const response = await api.searchOrders(queryParams);
            setSearchResults(response.data);
        }
        catch (error) {
            console.error('Error applying filters:', error);
        }
    };
    useEffect(() => {
        applyFilters();
    }, [amount, sortByDate, sortByUrgency, activeCategory]);
    return (_jsx(_Fragment, { children: _jsx(_Fragment, { children: !showCategories ? (_jsxs("div", { className: "relative", children: [_jsx("div", { className: "relative", children: _jsxs("div", { className: "fixed w-full top-0 z-10 bg-[#000015]", children: [_jsx(FilterHeader, { RawAddress: RawAddress, setActiveScreen: setActiveScreen }), _jsx("div", { className: "header-border" })] }) }), _jsxs(Container, { className: "mt-5 px-5 pb-[85px] pt-[65px]", children: [_jsxs("div", { className: "flex justify-between pb-5 cursor-pointer hover-link", onClick: () => setShowCategories(true), children: [_jsxs("div", { className: "flex items-center gap-3.5", children: [_jsx("span", { className: "text-[24px]", children: "\uD83E\uDDE9" }), _jsxs("div", { children: [_jsx("span", { className: "opacity-40", children: t('home.filter-category') }), _jsx("div", { className: "text-[16px] leading-[125%] mt-1", children: t(activeCategory.title) })] })] }), _jsx(SvgSelector, { id: "chevron-right" })] }), _jsx("div", { className: "decor-line mb-5" }), _jsxs("div", { className: "flex justify-between pb-5 cursor-pointer hover-link", children: [_jsxs("div", { className: "flex items-center gap-3.5", children: [_jsx("span", { className: "text-[24px]", children: "\uD83C\uDF0E" }), _jsxs("div", { children: [_jsx("span", { className: "opacity-40", children: t('home.filter-lang-label') }), _jsx("div", { className: "text-[16px] leading-[125%] mt-1", children: t('home.filter-lang-lang') })] })] }), _jsx(SvgSelector, { id: "chevron-right" })] }), _jsx("div", { className: "decor-line mb-5" }), _jsxs("div", { className: "flex items-center gap-3 pb-5", children: [_jsx("div", { className: "text-[24px]", children: "\uD83D\uDC8E" }), _jsx("input", { className: "bg-transparent w-full h-[40px] text-[16px] focus:outline-none", type: "number", placeholder: amountPlaceholder, value: amount === undefined ? '' : amount, onChange: handleAmountChange, onFocus: () => {
                                            if (amount === undefined)
                                                setAmountPlaceholder('');
                                        }, onBlur: () => {
                                            if (amount === undefined) {
                                                setAmountPlaceholder('Стоимость заданий от');
                                            }
                                        } })] }), _jsx("div", { className: "decor-line mb-5" }), _jsx("div", { className: "text-[14px] font-semibold mb-5", children: t('home.filter-title') }), _jsx("div", { className: "flex justify-between items-center", children: _jsxs("label", { className: "cursor-pointer flex items-center w-full justify-between", children: [_jsx("span", { className: "mr-1 opacity-40", children: t('home.filter-sort1') }), _jsx("input", { type: "radio", className: "appearance-none h-4 w-4 border border-gray-500 rounded-full checked:bg-blue-500 checked:border-transparent focus:outline-none cursor-pointer", checked: sortByDate, onChange: () => {
                                                setSortByDate(true);
                                                setSortByUrgency(false);
                                            } })] }) }), _jsx("div", { className: "decor-line my-5" }), _jsx("div", { className: "flex justify-between items-center pb-5", children: _jsxs("label", { className: "cursor-pointer flex items-center w-full justify-between", children: [_jsx("span", { className: "mr-1 opacity-40", children: t('home.filter-sort2') }), _jsx("input", { type: "radio", className: "appearance-none h-4 w-4 border border-gray-500 rounded-full checked:bg-blue-500 checked:border-transparent focus:outline-none cursor-pointer", checked: sortByUrgency, onChange: () => {
                                                setSortByDate(false);
                                                setSortByUrgency(true);
                                            } })] }) }), _jsx("div", { className: "decor-line mb-5" })] }), _jsx("div", { className: "fixed bottom-0 left-0 right-0 flex items-center justify-center bg-black-gradient", children: _jsx("div", { className: "w-full max-w-[375px] py-8 px-5", children: _jsxs(Button, { size: "LG", className: "text-[16px] font-bold", onClick: () => setActiveScreen('tasks'), children: ["\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C ", searchResults.length, searchResults.length === 1
                                        ? ' ' + t('common.task1')
                                        : searchResults.length === 0
                                            ? ' ' + t('common.task2')
                                            : ' ' + t('common.task3')] }) }) })] })) : (_jsx(Category, { RawAddress: RawAddress, setShowCategories: setShowCategories, setActiveCategory: setActiveCategory })) }) }));
};
