import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-cycle */
import { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Button } from '../ui/button';
import { NewTaskContext } from '../../pages/create-task';
import CALENDAR from '../../assets/icons/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
export const ThirdStep = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const startDatePickerRef = useRef(null);
    const endDatePickerRef = useRef(null);
    const { t } = useTranslation();
    const { setCurrentStep, setTaskData, taskData, currentStep } = useContext(NewTaskContext);
    const handleStartDateIconClick = () => {
        if (startDatePickerRef.current) {
            startDatePickerRef.current.setOpen(true);
        }
    };
    const handleEndDateIconClick = () => {
        if (endDatePickerRef.current) {
            endDatePickerRef.current.setOpen(true);
        }
    };
    const handleStartDateChange = (date) => {
        setStartDate(date);
    };
    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    const handleNextClick = () => {
        setTaskData({
            ...taskData,
            dateStart: startDate,
            dateEnd: endDate
        });
        setCurrentStep(currentStep + 1);
    };
    return (_jsxs("div", { className: "flex flex-col min-h-[80vh] justify-between", children: [_jsxs("div", { children: [_jsx("h3", { className: "text-base font-bold leading-[156%] mb-2.5", children: t('create-task-page.third-step-page.title') }), _jsxs("div", { className: "flex flex-col gap-2.5 mb-2", children: [_jsx("label", { children: t('create-task-page.third-step-page.label') }), _jsxs("div", { className: "flex gap-[5px] justify-between mt-[31px] pb-5 cursor-pointer relative", onClick: handleStartDateIconClick, children: [_jsxs("div", { className: "flex items-center gap-[15px]", children: [_jsx("img", { src: CALENDAR, alt: "calendar" }), _jsx("div", { className: "opacity-30 text-[16px] w-full", children: startDate
                                                    ? startDate.toLocaleString('en-GB', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric'
                                                    })
                                                    : t('create-task-page.third-step-page.startDate') })] }), _jsx(DatePicker, { selected: startDate, onChange: handleStartDateChange, showTimeSelect: true, dateFormat: "Pp", ref: startDatePickerRef, className: "hidden" })] }), _jsx("div", { className: "decor-line" }), _jsxs("div", { className: "flex gap-[5px] justify-between mt-[31px] pb-5 cursor-pointer relative", onClick: handleEndDateIconClick, children: [_jsxs("div", { className: "flex items-center gap-[15px]", children: [_jsx("img", { src: CALENDAR, alt: "calendar" }), _jsx("div", { className: "opacity-30 text-[16px] w-full", children: endDate
                                                    ? endDate.toLocaleString('en-GB', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric'
                                                    })
                                                    : t('create-task-page.third-step-page.endDate') })] }), _jsx(DatePicker, { selected: endDate, onChange: handleEndDateChange, showTimeSelect: true, dateFormat: "Pp", ref: endDatePickerRef, className: "hidden" })] }), _jsx("div", { className: "decor-line" })] })] }), _jsx("div", { children: _jsx(Button, { size: "LG", disabled: !!(startDate === null || endDate === null), onClick: handleNextClick, className: "w-full xs:w-[375px] mx-auto", children: t('common.next') }) })] }));
};
