import axios from 'axios';
export class SomeAPI {
    constructor(baseURL = 'https://somebackend.just-dmitry.ru') {
        this.baseURL = baseURL;
    }
    async getStatistics() {
        return axios.get(`${this.baseURL}/api/stat`);
    }
    async searchOrders(queryParams) {
        return axios.get(`${this.baseURL}/api/search`, { params: queryParams });
    }
}
