import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useState } from 'react';
import { Container } from '../../components/container';
import { CreateHeader } from './create-header';
import { FirstStep } from '../../components/task-create/first-step';
import { SecondStep } from '../../components/task-create/second-step';
import { ThirdStep } from '../../components/task-create/third-step';
import { FourthStep } from '../../components/task-create/fourth-step';
import { FifthStep } from '../../components/task-create/fifth-step';
import { SixthStep } from '../../components/task-create/sixth-step';
export const NewTaskContext = createContext({
    currentStep: 0,
    taskData: {},
    setCurrentStep: (value) => { },
    setTaskData: (data) => { }
});
export const CreateTask = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [taskData, setTaskData] = useState({
        category: '',
        task: '',
        dateStart: null,
        dateEnd: null,
        budget: 0,
        taskDescription: '',
        file: undefined
    });
    return (_jsx(NewTaskContext.Provider, { value: {
            taskData,
            currentStep,
            setCurrentStep,
            setTaskData
        }, children: _jsxs("div", { className: "relative", children: [_jsx("div", { children: _jsxs("div", { className: "fixed w-full top-0 z-10 bg-[#000015]", children: [_jsx(CreateHeader, { currentStep: currentStep, setCurrentStep: setCurrentStep, category: taskData.category }), _jsx("div", { className: "header-border" })] }) }), _jsxs(Container, { className: 'mt-5 h-full pt-[70px] overflow-y-auto', children: [currentStep === 1 && _jsx(FirstStep, {}), currentStep === 2 && _jsx(SecondStep, {}), currentStep === 3 && _jsx(ThirdStep, {}), currentStep === 4 && _jsx(FourthStep, {}), currentStep === 5 && _jsx(FifthStep, {}), currentStep === 6 && _jsx(SixthStep, {})] })] }) }));
};
