export const ROUTES = {
    HOME: '/',
    TASK_DETAIL: '/task-detail',
    MENU: '/menu',
    CREATE_TASK: '/create-task',
    MY_TASKS: '/my-tasks',
    PROFILE: '/profile',
    CONNECT: '/connect',
    // Congratulations
    DESIGNATED_PERFORMER: '/designated-performer',
    TASK_CREATED: '/task-created',
    RESPONSE_SENT: '/response-sent',
    TASK_COMPLETED: '/task-completed',
    SUBMITED_ARBITRATION: '/submited-arbitration',
    IS_EDITED: '/is-edited',
    // My Tasks
    OWN_AWAITING_PAYMENT: '/own-await-payment',
    IN_ARBITRATION: '/in-arbitration',
    ACTIVE_TASKS: '/active-tasks',
    IN_MODERATION: '/in-moderation',
    COMPLETED: '/completed',
    // RESPONSES
    RESPONSES_AWAITING_PAYMENT: '/responses-await-payment',
    RESPONSES_IN_ARBITRATION: '/responses-in-arbitration',
    RESPONSES_ACTIVE_TASKS: '/responses-active-tasks',
    RESPONSES_IN_MODERATION: '/responses-in-moderation',
    RESPONSES_COMPLETED: '/responses-completed'
};
