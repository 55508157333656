import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, useNavigate } from 'react-router-dom';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from '../../components/language-selector';
import { MenuProfile } from '../../components/menu-profile';
import { MenuHeader } from './menu-header';
export const Menu = () => {
    const RawAddress = useTonAddress();
    const { t } = useTranslation();
    const [tonConnectUI] = useTonConnectUI();
    const navigate = useNavigate();
    const menuData = [
        {
            title: 'menu-page.menu.cat1',
            href: '/create-task'
        },
        {
            title: 'menu-page.menu.cat2',
            href: '/'
        },
        {
            title: 'menu-page.menu.cat3',
            href: '/my-tasks'
        }
    ];
    return (_jsxs("div", { className: "flex flex-col justify-between min-h-screen relative !p-0", children: [_jsx("div", {}), _jsxs("div", { className: "fixed w-full top-0 z-10 bg-[#000015]", children: [_jsx(MenuHeader, { RawAddress: RawAddress }), _jsx("div", { className: "header-border" })] }), _jsxs("div", { className: `flex flex-col h-[80vh] min-h-[500px] gap-[63px] px-[20px] ${RawAddress ? 'justify-start pt-[40px]' : 'justify-center'}`, children: [RawAddress && _jsx(MenuProfile, {}), _jsx("div", { className: "flex flex-col justify-center items-center relative mx-auto", children: menuData.map((el, index) => {
                            const handleMenuItem = () => {
                                if (index === 1) {
                                    navigate(el.href);
                                }
                                else {
                                    !RawAddress ? tonConnectUI.connectWallet() : navigate(el.href);
                                }
                            };
                            return (_jsxs("button", { className: `text-[18px] leading-[50px] font-bold w-max text-center transition-all duration-300 text-[#31A6F5] hover:text-white ${index === 1 ? 'relative bg-white-active' : ''}`, onClick: handleMenuItem, children: [t(el.title), index === 1 && (_jsx(_Fragment, { children: _jsx("span", { className: "absolute inset-0 flex items-center menu-gradient justify-center text-[18px] font-bold opacity-100 bg-white-active", children: t(el.title) }) }))] }, el.title));
                        }) })] }), _jsx("div", { className: "pb-[30px] px-5 max-w-[700px] w-full mx-auto my-0 flex flex-col overflow-hidden", children: _jsxs("div", { className: "mt-auto flex items-center justify-between gap-2", children: [_jsx(Link, { to: "#", className: "text-[#31A6F5] hover:text-white", children: t('menu-page.menu-footer.support') }), _jsx(LanguageSelector, {})] }) })] }));
};
