import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v1 } from 'uuid';
import { HomeHeader } from './home-header';
import { TaskItem } from '../../components/task-item';
import { Button } from '../../components/ui/button';
import { Filter } from './filter';
import { SomeAPI } from '../../logic/some-api';
import useDebounce from '../../hooks/useDebounce';
import FILTER from '../../assets/icons/filter.svg';
export const Home = () => {
    const [searchResults, setSearchResults] = useState([]);
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const debouncedSearchQuery = useDebounce(searchQuery, 500);
    useEffect(() => {
        const api = new SomeAPI();
        const queryParams = { query: debouncedSearchQuery };
        api.searchOrders(queryParams)
            .then((response) => {
            setSearchResults(response.data);
        })
            .catch((error) => {
            console.error('Error searching orders:', error);
        });
    }, [debouncedSearchQuery]);
    const [activeScreen, setActiveScreen] = useState('tasks');
    return (_jsx("div", { className: "relative", children: activeScreen === 'tasks' ? (_jsxs("div", { className: "relative", children: [_jsxs("div", { className: "fixed w-full top-0 z-10 bg-[#000015]", children: [_jsx(HomeHeader, { searchQuery: searchQuery, setSearchQuery: setSearchQuery }), _jsx("div", { className: "header-border" })] }), _jsx("div", { className: "mt-3.5 overflow-y-auto h-full max-w-[700px] mx-auto my-0 flex flex-col px-[20px] pt-[80px] pb-[70px]", children: searchResults.length < 1 ? (_jsx("div", { className: "flex justify-center items-center h-[70vh] text-[18px] text-center", children: t('home.not-found') })) : searchResults.map((el) => (_jsx(TaskItem, { el: el }, v1()))) }), _jsx(Button, { onClick: () => setActiveScreen('filter'), size: "BASE", endIcon: FILTER, className: "fixed bottom-[20px] left-1/2 transform -translate-x-1/2 z-10 xs:px-[25px] xp:py-[15px] xs:text-[18px]", children: t('home.filter-btn') })] })) : (_jsx(Filter, { setActiveScreen: setActiveScreen, searchResults: searchResults, setSearchResults: setSearchResults })) }));
};
