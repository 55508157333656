import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const languages = [
    { code: 'ru', label: 'Русский' },
    { code: 'en', label: 'English' }
];
export const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const savedLanguage = localStorage.getItem('i18nextLng');
    const [selectedLanguage, setSelectedLanguage] = useState(savedLanguage || languages[0].code);
    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('i18nextLng', selectedLanguage);
    }, [selectedLanguage, i18n]);
    const toggleLanguage = () => {
        setSelectedLanguage(selectedLanguage === 'ru' ? 'en' : 'ru');
    };
    return (_jsx("div", { className: "relative", children: _jsx("button", { className: 'cursor-pointer text-[#31A6F5] hover:text-white bg-transparent', onClick: toggleLanguage, children: selectedLanguage === 'ru' ? 'English' : 'Русский' }) }));
};
