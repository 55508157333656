import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from '../../../components/container';
import { LinkItem } from '../../../components/link-item';
import { ResponsesTasksHeader } from '../own-tasks-header';
const awaitingPaymentData = [
    {
        id: '1213',
        title: 'Freelance-exchange TDA development (part II)',
        dateStart: '10 июня, 21:00',
        dateEnd: '20 июня, 15:00',
        amount: 777
    },
    {
        id: 'qwe5q12',
        title: 'Freelance-exchange TDA development (part II)',
        dateStart: '10 июня, 21:00',
        dateEnd: '20 июня, 15:00',
        amount: 777
    }
];
export const AwaitingPayment = () => _jsxs("div", { className: "relative", children: [_jsx("div", { children: _jsxs("div", { className: "fixed w-full top-0 z-10 bg-[#000015]", children: [_jsx(ResponsesTasksHeader, {}), _jsx("div", { className: "header-border" })] }) }), _jsx(Container, { className: "pt-[60px]", children: awaitingPaymentData.map(el => (_jsx(LinkItem, { ...el }, el.id))) })] });
