import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-cycle */
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NewTaskContext } from '../../pages/create-task';
import { Button } from '../ui/button';
import { ROUTES } from '../../utils/router';
import FILE from '../../assets/file.svg';
import FILE_BG from '../../assets/upload-bg.svg';
export const SixthStep = () => {
    const [fileData, setFileData] = useState(undefined);
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const { setTaskData, taskData } = useContext(NewTaskContext);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFileData(selectedFile);
        }
    };
    const handleClick = () => {
        setTaskData({ ...taskData, file: fileData });
        navigate(ROUTES.TASK_CREATED);
    };
    return (_jsxs("div", { className: "flex flex-col min-h-[80vh] justify-between", children: [_jsxs("div", { children: [_jsx("h3", { className: "text-base font-bold leading-[156%] mb-2.5 text-center", children: t('create-task-page.sixth-step-page.title') }), _jsx("div", { className: "text-center opacity-40", children: t('create-task-page.sixth-step-page.content') }), _jsx("div", { className: "flex flex-col gap-2.5 mb-2 mt-[100px]", children: !fileData ? (_jsxs("div", { className: "w-full", children: [_jsx("input", { type: "file", className: "bg-transparent focus:outline-none mx-auto w-auto hidden", onChange: handleFileChange, ref: fileInputRef }), _jsx("div", { className: "bg-transparent mx-auto w-auto cursor-pointer", onClick: () => fileInputRef.current?.click(), children: _jsx("img", { src: FILE_BG, alt: "File BG", className: "block mx-auto cursor-pointer" }) })] })) : (_jsxs("div", { className: 'w-full flex items-center flex-col justify-center', children: [_jsx("img", { src: FILE, alt: "File" }), _jsxs("div", { className: "mt-[15px]", children: [fileData.name, _jsx("button", { onClick: () => setFileData(undefined), className: "pl-[5px]", children: "\u274C" })] })] })) })] }), _jsx("div", { children: _jsx(Button, { size: "LG", disabled: !fileData, onClick: handleClick, className: "w-full xs:w-[375px] mx-auto", children: t('create-task-page.sixth-step-page.create-task') }) })] }));
};
