export const CategoriesList = [
    {
        title: 'categories.cat1',
        category: 'turnkey-project'
    },
    {
        title: 'categories.cat2',
        category: 'smart-contracts'
    },
    {
        title: 'categories.cat3',
        category: 'bots-and-web-apps'
    },
    {
        title: 'categories.cat4',
        category: 'websites-and-de-frontend'
    },
    {
        title: 'categories.cat5',
        category: 'backend-and-blockchain-interactions'
    },
    {
        title: 'categories.cat6',
        category: 'ux-ui-graphic-design-and-3d'
    },
    {
        title: 'categories.cat7',
        category: 'frontend'
    },
    {
        title: 'categories.cat8',
        category: 'nft'
    },
    {
        title: 'categories.cat9',
        category: 'texts-documentation-copywriting'
    }
];
