import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TonClient, Address } from 'ton';
import { useTonAddress } from '@tonconnect/ui-react';
import { ROUTES } from './utils/router';
import './utils/i18n';
import { Home } from './pages/home';
import { TaskDetail } from './pages/task-detail';
import { Menu } from './pages/menu';
import { CreateTask } from './pages/create-task';
import { MyTasks } from './pages/my-tasks';
import { Profile } from './pages/profile';
// Congratulations
import { DesignatedPerformer } from './pages/congratulations/designated-performer';
import { TaskCreated } from './pages/congratulations/task-created';
import { ResponseSent } from './pages/congratulations/response-sent';
import { TaskCompleted } from './pages/congratulations/task-completed';
import { SubmitedArbitration } from './pages/congratulations/submited-arbitration';
// Own My Tasks
import { AwaitingPayment } from './pages/own-tasks/awaiting-payment';
import { InArbitration } from './pages/own-tasks/in-arbitration';
import { ActiveTasks } from './pages/own-tasks/active-tasks';
import { InModeration } from './pages/own-tasks/in-moderation';
import { Completed } from './pages/own-tasks/completed';
// Responses Tasks
import { AwaitingPayment as ResponsesAwaitingPayment } from './pages/responses/awaiting-payment';
import { InArbitration as ResponsesInArbitration } from './pages/responses/in-arbitration';
import { ActiveTasks as ResponsesActiveTasks } from './pages/responses/active-tasks';
import { InModeration as ResponsesInModeration } from './pages/responses/in-moderation';
import { Completed as ResponsesCompleted } from './pages/responses/completed';
const isTestnet = window.location.host.indexOf('localhost') >= 0
    ? true
    : window.location.href.indexOf('testnet') >= 0;
export const App = () => {
    const [balance, setBalance] = useState(undefined);
    const [tonClient, setTonClient] = useState(new TonClient({
        endpoint: isTestnet
            ? 'https://testnet.tonhubapi.com/jsonRPC'
            : 'https://mainnet.tonhubapi.com/jsonRPC'
    }));
    const RawAddress = useTonAddress();
    useEffect(() => {
        if (RawAddress) {
            tonClient.getBalance(Address.parse(RawAddress)).then((bl) => {
                setBalance(bl.toString());
            });
        }
    }, [RawAddress]);
    return (_jsx(_Fragment, { children: _jsxs(Routes, { children: [_jsx(Route, { path: ROUTES.HOME, element: _jsx(Home, {}) }), _jsx(Route, { path: ROUTES.TASK_DETAIL, element: _jsx(TaskDetail, {}) }), _jsx(Route, { path: ROUTES.MENU, element: _jsx(Menu, {}) }), _jsx(Route, { path: ROUTES.CREATE_TASK, element: _jsx(CreateTask, {}) }), _jsx(Route, { path: ROUTES.MY_TASKS, element: _jsx(MyTasks, {}) }), _jsx(Route, { path: ROUTES.PROFILE, element: _jsx(Profile, {}) }), _jsx(Route, { path: ROUTES.DESIGNATED_PERFORMER, element: _jsx(DesignatedPerformer, {}) }), _jsx(Route, { path: ROUTES.TASK_CREATED, element: _jsx(TaskCreated, {}) }), _jsx(Route, { path: ROUTES.RESPONSE_SENT, element: _jsx(ResponseSent, {}) }), _jsx(Route, { path: ROUTES.TASK_COMPLETED, element: _jsx(TaskCompleted, {}) }), _jsx(Route, { path: ROUTES.SUBMITED_ARBITRATION, element: _jsx(SubmitedArbitration, {}) }), _jsx(Route, { path: ROUTES.OWN_AWAITING_PAYMENT, element: _jsx(AwaitingPayment, {}) }), _jsx(Route, { path: ROUTES.IN_ARBITRATION, element: _jsx(InArbitration, {}) }), _jsx(Route, { path: ROUTES.ACTIVE_TASKS, element: _jsx(ActiveTasks, {}) }), _jsx(Route, { path: ROUTES.IN_MODERATION, element: _jsx(InModeration, {}) }), _jsx(Route, { path: ROUTES.COMPLETED, element: _jsx(Completed, {}) }), _jsx(Route, { path: ROUTES.RESPONSES_AWAITING_PAYMENT, element: _jsx(ResponsesAwaitingPayment, {}) }), _jsx(Route, { path: ROUTES.RESPONSES_IN_ARBITRATION, element: _jsx(ResponsesInArbitration, {}) }), _jsx(Route, { path: ROUTES.RESPONSES_ACTIVE_TASKS, element: _jsx(ResponsesActiveTasks, {}) }), _jsx(Route, { path: ROUTES.RESPONSES_IN_MODERATION, element: _jsx(ResponsesInModeration, {}) }), _jsx(Route, { path: ROUTES.RESPONSES_COMPLETED, element: _jsx(ResponsesCompleted, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] }) }));
};
