import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { ROUTES } from '../../../utils/router';
import { SvgSelector } from '../../../assets/icons/svg-selector';
import MINI_AVATAR from '../../../assets/mini-avatar.png';
export const TaskHeader = ({ isSignIn }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex items-center justify-between gap-[20px] p-[20px] font-semibold", children: [_jsxs("div", { className: "flex items-center gap-[10px]", children: [_jsx("button", { onClick: () => navigate(-1), children: _jsx(SvgSelector, { id: "back" }) }), _jsxs("h2", { className: "text-[#3A4362] text-[14px] leading-7", children: [t('common.task1'), " #234567"] })] }), _jsxs("div", { className: "flex items-center gap-3.5", children: [isSignIn && (_jsx(Link, { to: ROUTES.PROFILE, children: _jsx("img", { src: MINI_AVATAR, className: "rounded-full border border-gray-400", alt: "avatar" }) })), _jsx("button", { onClick: () => navigate(ROUTES.MENU), children: _jsx(SvgSelector, { id: "burger-gray" }) })] })] }) }));
};
