import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useTonAddress } from '@tonconnect/ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Container } from '../../components/container';
import { TaskHeader } from './task-header';
import { Task } from './task/task';
import { UserProfile } from './user-profile';
import { SignContract } from './sign-contract';
import { UsersResponses } from './users-response';
import { AddResponse } from './add-reponse';
import { AddBudget } from './add-budget';
export const TaskDetail = () => {
    const [tabs, setTabs] = useState('TASK');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeUser, setActiveUser] = useState(null);
    // Назначить Юзера
    const [assignUser, setAssignUser] = useState(false);
    // Заключить смарт-контракт
    const [signContract, setSignContract] = useState(false);
    // Откликнуться
    const [respond, setRespond] = useState(false);
    // Дата Отклика
    const [respondData, setRespondData] = useState({
        text: '',
        budget: 0
    });
    // Для перехода на следующий шаг
    const [respondBudget, setRespondBudget] = useState(false);
    const [status, setStatus] = useState('active');
    const isSignIn = useTonAddress();
    const isOwn = true;
    const TaskDetailContent = () => {
        if (!isOwn && isSignIn && respondBudget) {
            return _jsx(AddBudget, { setRespondBudget: setRespondBudget, setRespondData: setRespondData, respondData: respondData });
        }
        if (!isOwn && isSignIn && respond) {
            return _jsx(AddResponse, { respondData: respondData, setRespondData: setRespondData, setRespondBudget: setRespondBudget, setRespond: setRespond });
        }
        if (!assignUser && !respondBudget) {
            return (_jsxs("div", { className: "relative", children: [_jsx("div", { children: _jsxs("div", { className: "fixed w-full top-0 z-10 bg-[#000015]", children: [_jsx(TaskHeader, { isSignIn: isSignIn }), _jsx("div", { className: "header-border" })] }) }), isOwn && status === 'active' && (_jsxs(Container, { className: "mt-3.5 flex flex-col justify-center pt-[75px]", children: [_jsxs("div", { className: "flex items-center justify-center gap-4 uppercase pb-3.5 whitespace-nowrap leading-[180%] tracking-[0.73px] active:outline-none focus:outline-none", children: [_jsx("button", { className: `w-[115px] ${tabs !== 'TASK' ? 'text-blue_color' : ''}`, onClick: () => setTabs('TASK'), children: t('common.task1') }), _jsxs("button", { className: `w-[115px] ${tabs !== 'RESPONSES' ? 'text-blue_color' : ''}`, onClick: () => setTabs('RESPONSES'), children: [t('common.feedback-l'), " (3)"] })] }), _jsx("div", { className: "decor-line" })] })), _jsx(Container, { className: `mt-5 pb-[100px] ${isOwn && isSignIn && status !== 'active'
                            ? 'pb-[150px]'
                            : ''}`, children: tabs === 'TASK' ? (_jsx(Task, { status: status, isSignIn: isSignIn, isOwn: isOwn, setTabs: setTabs, setRespond: setRespond })) : (_jsx(UsersResponses, { activeUser: activeUser, setActiveUser: setActiveUser, setAssignUser: setAssignUser })) })] }));
        }
        if (assignUser && !signContract) {
            return _jsx(UserProfile, { setAssignUser: setAssignUser, setSignContract: setSignContract });
        }
        if (assignUser && signContract) {
            return _jsx(SignContract, {});
        }
        return null;
    };
    return (_jsx(_Fragment, { children: TaskDetailContent() }));
};
