import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable default-case */
/* eslint-disable prefer-const */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/router';
import { SvgSelector } from '../../assets/icons/svg-selector';
export const ResponsesTasksHeader = () => {
    const location = useLocation();
    const [pageTitle, setPageTitle] = useState('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        let title = '';
        switch (location.pathname) {
            case ROUTES.RESPONSES_AWAITING_PAYMENT:
                title = `${t('my-tasks-page.awaiting-payment')} · 2`;
                break;
            case ROUTES.RESPONSES_IN_ARBITRATION:
                title = `${t('my-tasks-page.in_arbitration')} · 2`;
                break;
            case ROUTES.RESPONSES_ACTIVE_TASKS:
                title = `${t('my-tasks-page.active_tasks')} · 2`;
                break;
            case ROUTES.RESPONSES_IN_MODERATION:
                title = `${t('my-tasks-page.in_moderation')} · 2`;
                break;
            case ROUTES.RESPONSES_COMPLETED:
                title = `${t('my-tasks-page.completed')} · 2`;
                break;
        }
        setPageTitle(title);
    }, [location.pathname]);
    return (_jsxs("div", { className: "relative px-[20px]", children: [_jsxs("div", { className: "flex items-center justify-between gap-[15px] py-[15px]", children: [_jsx("button", { onClick: () => navigate(-1), className: "bg-transparent", children: _jsx(SvgSelector, { id: "back" }) }), _jsx("h3", { className: "text-sm leading-7 text-center", children: pageTitle }), _jsx("div", {})] }), _jsx("div", { className: "header-border" })] }));
};
