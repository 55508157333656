import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../utils/router';
import { SvgSelector } from '../../../../assets/icons/svg-selector';
import MINI_AVATAR from '../../../../assets/mini-avatar.png';
export const FilterHeader = ({ RawAddress, setActiveScreen }) => {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex items-center justify-between flex-wrap gap-[15px] p-[20px]", children: [_jsxs("button", { onClick: () => setActiveScreen('tasks'), className: "flex gap-3 items-center text-[#3A4362] leading-[214%] text-[14px] font-semibold", children: [_jsx(SvgSelector, { id: "back" }), t('home.filters')] }), _jsxs("div", { className: 'flex gap-5 items-center', children: [RawAddress && (_jsx(Link, { to: ROUTES.PROFILE, children: _jsx("img", { src: MINI_AVATAR, className: "rounded-full border border-gray-400", alt: "avatar" }) })), _jsx(Link, { to: ROUTES.MENU, children: _jsx(SvgSelector, { id: "burger-gray" }) })] })] }) }));
};
