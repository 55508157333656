import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-cycle */
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewTaskContext } from '../../pages/create-task';
import { Button } from '../ui/button';
export const SecondStep = () => {
    const [taskValue, setTaskValue] = useState('');
    const { t } = useTranslation();
    const { setCurrentStep, setTaskData, taskData, currentStep } = useContext(NewTaskContext);
    const handleNextClick = () => {
        setTaskData({
            ...taskData,
            task: taskValue
        });
        setCurrentStep(currentStep + 1);
    };
    return (_jsxs("div", { className: "flex flex-col min-h-[80vh] justify-between", children: [_jsxs("div", { children: [_jsx("h3", { className: "text-base font-bold leading-[156%] mb-2.5", children: t('create-task-page.second-step-page.title') }), _jsxs("div", { className: "flex flex-col gap-2.5 mb-2", children: [_jsx("label", { children: t('create-task-page.second-step-page.label') }), _jsx("input", { type: "text", className: "bg-transparent focus:outline-none", value: taskValue, onChange: (e) => setTaskValue(e.target.value) }), _jsx("div", { className: "decor-line" })] }), _jsx("span", { className: "opacity-40", children: t('create-task-page.second-step-page.info') })] }), _jsx("div", { children: _jsx(Button, { size: "LG", disabled: taskValue.length < 1, onClick: handleNextClick, className: "w-full xs:w-[375px] mx-auto", children: t('common.next') }) })] }));
};
