import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { ROUTES } from '../../utils/router';
export const TaskItem = ({ el }) => {
    const formatDateTime = (dateTimeString) => {
        const date = parseISO(dateTimeString);
        return format(date, 'd MMMM, HH:mm', { locale: ru });
    };
    return (_jsxs("div", { className: "mb-3.5", children: [_jsxs("div", { className: "pb-3.5 text-white", children: [_jsx("div", { className: "mb-1", children: _jsx(Link, { to: ROUTES.TASK_DETAIL, className: "leading-5 hover-blue", children: el.name }) }), _jsxs("span", { className: "opacity-40 text-[10px] leading-4 mb-1", children: [formatDateTime(el.starting_unix_time), " \u2013 ", formatDateTime(el.ending_unix_time)] }), _jsxs("div", { className: "flex items-center gap-[1px]", children: ["\uD83D\uDC8E ", _jsx("span", { children: el.amount })] })] }), _jsx("div", { className: "decor-line" })] }));
};
